* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

.frustratedContainer{
    background-image: url(../../Resources/landing\ page\ new-6\ bg.avif);
    background-position: cover;
    background-size: 100% auto;
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.frustratedImage{
    width: 30vw;
    height: 35vw;
}

.frustratedImage img{
    width: 100%;
    height: 100%;
}

.help{
    width: 30vw;
    height: 30vw;
}

.help img{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 575px){
    .frustratedContainer{
        display: flex;
        flex-direction: column;
        height: 85vh;
        gap: 5vw;

    }
    .frustratedImage{
        width: 60vw;
        height: 70vw;
        margin-top: 10vw;
    }
    
    .frustratedImage img{
        width: 100%;
        height: 100%;
    }
    
    .help{
        width: 70vw;
        height: 70vw;
    }
    
    .help img{
        width: 100%;
        height: 100%;
    }
}