* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  /* overflow-x: hidden;
  overflow-y: auto; */
  scroll-behavior: auto;
}

.growthContainer {
  background-image: url(../../Resources/landing\ page\ new-7\ bg.avif);
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100vw;
  height: auto;
  padding: 8vw;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.growthSec {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 80vw;
}

.growth {
  width: 23vw;
  height: 16vw;
}

.growth img {
  width: 100%;
  height: 100%;
}

.GrowthForm {
  width: 35vw;
}

.growthFormIn {
  /* border: 5px solid rgb(44, 44, 44); */
  margin-top: 3vw;
  border-radius: 1vw;
}

.growthFormIn input {
  width: 25vw;
  height: 2.5vw;
  border-radius: 0.5vw;
  margin: 3vw 4vw 0 4vw;
  border: none;
  font-size: 1vw;
  padding-left: 1vw;
}

.GrowthForm h1 {
  font-size: 3.2vw;
  font-weight: 800;
  letter-spacing: 1px;
  text-align: center;
}

.button {
  width: 20vw;
  font-size: 1.2vw;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 1vw;
  margin: 3vw 7vw 3vw 7vw;
  background-color: #175779;
  color: #fcfcfc;
  border-radius: 0.6vw;
  background-image: linear-gradient(200deg, #175779 50%, #d1b442 100%);
}

@media only screen and (max-width: 575px) {
  .growthContainer {
    background-image: url(../../Resources/landing\ page\ new-8\ mobile\ bg.avif);
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: 100vw;
    height: 75vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }

  .growthSec {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 80vw;
    gap: 15vw;
  }

  .growth {
    width: 55vw;
    height: 42vw;
  }

  .growth img {
    width: 100%;
    height: 100%;
  }

  .GrowthForm {
    width: 85vw;
    padding: 5vw;
  }

  .growthFormIn {
    /* border: 4px solid rgb(44, 44, 44); */
    margin-top: 4vw;
    margin-bottom: 2vw;
    border-radius: 4vw;
  }

  .GrowthForm h1 {
    font-size: 6vw;
    font-weight: 800;
    letter-spacing: 1px;
    text-align: center;
  }

  .growthFormIn input {
    width: 58vw;
    height: 7vw;
    border-radius: 1.8vw;
    margin: 5vw 7vw 3vw 7vw;
    border: none;
    font-size: 2.5vw;
    padding-left: 2vw;
    color: black;
  }

  .button {
    cursor: pointer;
    width: 68vw;
    height: 13vw;
    font-size: 4vw;
    text-align: center !important;
    padding: 3.5vw 1vw 1vw 1vw;
    font-weight: 600;
    background-color: #175779;
    color: #fcfcfc;
    border-radius: 2vw;
    margin: 10vw auto 0 auto;
    z-index: 10;
    background-image: linear-gradient(200deg, #175779 50%, #d1b442 100%);
  }
}
