body {
    margin: 0 !important;
    padding: 0 !important;
    scroll-behavior: auto;
    background-color: #f6f6f6 !important;
  }

  .CarouselContainer{
    width: 100vw;
    height: 70vh;
    margin-top: 10vw;
  }

  .ImageSlide{
    width: 80vw;
    height: 90vh;
  }

  .image{
    width: 70vw;
    height: 80vh;
    margin: 3vw auto;
  }

  .image img{
    width: 100%;
    height: 100%;
  }