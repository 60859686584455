* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  scroll-behavior: auto;
  background-color: #f6f6f6 !important;
}

.t2Container {
  background-color: #0d0d0d;
  background-image: url(../../Resources/testimonial-bg2.avif);
  background-position: center;
  background-size: 100vw 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.t2inner {
  margin: 0vw auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  margin-top: 4vw;
}

.card {
  width: 13vw;
  height: 13vw;
  background-color: #313131;
  border-radius: 1vw;
  /* border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to top, #0d6691 20%, #a9a932 90%, #a9a932 100%);
  border-image-slice: 1; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
}

.cardimage {
  width: 8vw;
  height: 8vw;
  position: relative;
  overflow: hidden;
}

.cardimage img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 1;
}

.card h3 {
  color: #fcfcfc;
  font-size: 1.3vw;
  font-weight: 700;
  background-image: linear-gradient(
    to bottom,
    #a9a932 20%,
    #a9a932 30%,
    #0d6691 80%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.card .name {
  font-size: 1vw;
  color: #fcfcfc;
  font-weight: 500;
}

.mobileCont {
  display: none;
}

@media only screen and (max-width: 575px) {
  .t2Container {
    display: none;
  }

  .mobileCont {
    display: block;
    background-image: url(../../Resources/testimonialmobilebg2.avif);
    background-position: center;
    background-size: 100vw 100%;
    background-repeat: no-repeat;
    width: 100vw;
    height: auto;
    padding-top: 7vh;
    padding-bottom: 5vh;
  }

  .mobileContinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .t2inner {
    margin: 2vw auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6vw;
    margin-top: 4vw;
  }

  .cardLeft {
    width: 40vw;
    height: 40vw;
    /* border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to top, #0d6691 20%, #a9a932 90%, #a9a932 100%);
    border-image-slice: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    border-radius: 2vw;
    background-color: #313131;
    animation: slideInFromLeft 2s ease;
  }

  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-200px); /* Move the element 200px to the left initially */
    }
    100% {
      transform: translateX(0); /* Move the element to its original position */
    }
  }

  .cardRight {
    width: 40vw;
    height: 40vw;
    /* border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to top, #0d6691 20%, #a9a932 90%, #a9a932 100%);
    border-image-slice: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    border-radius: 2vw;
    background-color: #313131;
    animation: slideInFromRight 2s ease;
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(200px); /* Move the element 200px to the left initially */
    }
    100% {
      transform: translateX(0px); /* Move the element to its original position */
    }
  }


  .cardimage {
    width: 25vw;
    height: 25vw;
    position: relative;
    overflow: hidden;
  }

  .cardimage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
  }

  .cardLeft .name, .cardRight .name {
    font-size: 3vw;
    color: #fcfcfc;
    font-weight: 500;
  }

  .cardLeft h3, .cardRight h3 {
    color: #fcfcfc;
    font-size: 3vw;
    margin-top: 1vw;
  }
}
