*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  scroll-behavior: auto;
}

.navbar1 {
  position: fixed;
  width: 100vw;
  background-color: #0f0f0f;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 0.5vw !important;
  flex-direction: row;
  margin-top: 6vh;
  gap: 5vw;
  /* border-bottom: 0.2px solid #706e6e !important; */
  z-index: 100;
}

.navMobile {
  display: none;
}

.logo {
  width: 12vw;
  height: 4.5vw;
  padding: 0px 5px 0.5vw 2px;
}

.logo img {
  width: 100%;
  height: 100%;
}



.navbar {
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 1vw;
  justify-content: space-between;
  align-items: center;
  gap: 2vw !important;
  margin-top: 0.5vw;
}

li {
  font-family: "Poppins", sans-serif;
  list-style: none;
}

.button {
  background-color: #175779;
  padding: 0.5vw 1vw 0.5vw 1vw;
  margin-top: 0vw;
  border-radius: 5px;
  cursor: pointer;
}

.button a{
  text-decoration: none;
  color: white;
}

.button:hover {
  color: black !important;
  font-weight: 600;
  background-color: #fcfcfc;
}

.button a:hover{
  color: black !important;
  font-weight: 600;
  background-color: #fcfcfc;
}

.navlink {
  text-decoration: none;
  display: block;
  position: relative;
  padding: 0.2em 0 0 0;
  color: #fcfcfc;
  cursor: pointer;
}

.navlink a{
  text-decoration: none;
  color: #fcfcfc;
}

.navlink::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  width: 100%;
  height: 0.15em;
  background-color: #2891c9;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.navlink::after {
  opacity: 1;
  transform: scale(0);
  transform-origin: center;
}

.navlink:hover::after,
.navlink:focus::after {
  transform: scale(1);
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media only screen and (max-width: 575px) {
   .navbar1 {
    display: none;
  }

  .navbar{
    display: none;
  }

  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }


  span {
    font-size: 30px;
    cursor: pointer;
  }

  .navMobile {
    display: block;
    width: 100vw;
    height: 14vw;
    background-color: #0f0f0f;
    /* border-bottom: 1px solid #706e6e; */
    
  }

  .menuButton{
    padding: 4vw 3vw;
  }

  .menuButton div{
    width: 20px;
    height: 1px;
    border: 0.5px solid white;
    margin-bottom: 5px;
  }

  .btn-primary{
    display: none !important;
    background-color: black !important;
  }

  button .text-bg-dark{
    background-color: black !important;
    border-radius: none;
  }


  .navigation{
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    gap: 40vw;
    background-color: black !important;
    z-index: 500;

  }

  .logo {
    width: 41vw;
    height: 14vw;
    padding: 1vw 1vw 1vw 4vw;
  }

  .logo img {
    width: 100%;
    height: 100%;
  }

  .navBody{
    margin-top: 10vw;
  }

  button .btn-close {
    color: white !important;
  }

  .navDetails{
    width: 68vw;
    height: 12vw;
    border-bottom: 2px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

   button.btn-close{
    color: white !important;
  } 

  header{
    padding: 15px 20px;
    color: #fcfcfc;
  }

  header svg{
    font-size: 6.5vw;
  }

  .sidenav{
    width: 100vw;
    background-color: #0f0f0f !important;
    position: absolute;
    height: 95vh;
    right: 0;
    padding: 0 5vw 0 3vw;
    transition: all 1s;
    z-index: 500;
    
  }


  ul{
    padding: 0;
    margin-top: 25vw !important;

  } 
   ul li{
    list-style-type: none;
    margin: 4.5vw 12vw 4.5vw 6vw;
    border-bottom: 0.1px solid #ffffff;
    padding-bottom: 5px;
   }

   ul li a, ul li div{
    text-decoration: none;
    color: #fcfcfc;
    font-size: 4vw !important;
   }

   .SocialIcons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    margin-top: 15vw;
   }

   .SocialIcons a{
    text-decoration: none;
    color: #ffffff;
   }
  
  
}
