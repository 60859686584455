* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  scroll-behavior: auto;
  background-color: #f6f6f6 !important;
}

@media only screen and (max-width: 575px) {
  .CarouselContainer{
    /* background-image: url(../../Resources/testimonialmobilebg1.avif);
    background-position: cover;
    background-size: 100vw auto;
    background-repeat: no-repeat;
    width: 100vw;
    height: 80vh; */
   
  }

  .mobileInner{
    margin: 0 auto !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10vw;
  }

  .mobileInner h1 {
    color: #fcfcfc;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(
      to top,
      #0d6691 20%,
      #a9a932 90%,
      #a9a932 100%
    );
    border-image-slice: 1;
    border-radius: 15px;
    width: 50vw;
    text-align: center;
    font-size: 6vw;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    padding: 2vw;
    margin: 0 auto;
  }

  .mobileInner p {
    font-size: 4vw;
    color: #fcfcfc;
    margin-top: 5vw !important;
    margin-bottom: 5vw !important;
    text-align: center;
    letter-spacing: 1px;
  }

  .Carousel{

  }

  .card {
    width: 55vw;
    height: 35vw;
    background-color: #313131;
    border-radius: 2vw;
    /* border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to top, #0d6691 20%, #a9a932 90%, #a9a932 100%);
    border-image-slice: 1; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5vw;
    margin-top: 30vw;
    margin: 15vw auto 15vw auto;
  }
  
  .cardimage {
    width: 28vw;
    height: 28vw;
    position: absolute;
    bottom: 57%;
    overflow: visible;
    z-index: 5;

  }
  
  .cardimage img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    z-index: 1;
  }
  
  .card h3 {
    color: #fcfcfc;
    font-size: 5vw;
    font-weight: 800;
    background-image: linear-gradient(
      to bottom,
      #a9a932 20%,
      #a9a932 30%,
      #0d6691 80%
    );
    -webkit-background-clip: text;
    color: transparent;
  }
  
  .card .name {
    margin-top: 12vw;
    font-size: 5vw !important;
    color: #fcfcfc;
    font-weight: 500;
  }


}
