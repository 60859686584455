.App {
  text-align: center;
}

body{
  scroll-behavior: initial;
 
}

p{
  margin: 0 !important;
  padding: 0 !important;
}


