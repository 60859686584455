* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  /* overflow-x: hidden; */
  /* overflow-y: auto; */
  scroll-behavior: auto;
}

.AboutContainer {
  background-image: url(../../../Resources/landing\ page\ new-1.2\ bg.avif);
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #000000;
  /* overflow-y: hidden; */
  width: auto;
  height: 90vh;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}

.AboutUs {
  width: 12vw;
  padding-top: 10vh;
  margin-top: 5vw;
  margin-left: 13vw;
}

.AboutUs img {
  width: 100%;
  height: 100%;
}

.ContainerFlex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3vw;
  margin: 10vw 10vw 3vw 10vw !important;
}

.Content {
  width: 37vw;
  font-size: 2.5vw;
  letter-spacing: -0.5px;
  height: 18vw;
  font-weight: bolder;
  font-family: "Montserrat", sans-serif;
  border-radius: 1vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #616161 0, white 10%, #757575 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  text-decoration: none;
}

@keyframes shine {
  0% {
    background-position: 0px;
  }

  60% {
    background-position: 480px;
  }

  100% {
    background-position: 900px;
  }
}

.MobileContent {
  display: none;
}

.videoContent {
  width: 31vw;
  height: 18vw;
  border-radius: 0.9vw;
  border: 2px solid white;
}

video {
  width: 100%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 100%;
  border-radius: 1vw;
}

.numbersMobileContainer {
  display: block;
  background-position: cover;
  background-size: 100% auto;
  width: 100vw;
  height: 19vw;
  color: #ffffff;
  background-color: #161616;
  /* margin-bottom: 5vw; */
}

.numbersBox {
  width: 100vw;
  height: 35vh;
  margin: 0 auto 10vw auto !important;
  padding-bottom: 10vh !important;
  background-color: #161616ef;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head h3 {
  font-size: 1.8vw !important;
  margin-top: 6vh;
  margin-bottom: 3vw;
  background-image: linear-gradient(
    to bottom,
    #0d6691 20%,
    #a9a932 90%,
    #a9a932 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.numbers1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  color: #ffffff;
  /* margin: 3vw auto 1vw auto !important; */
}

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18vw;
  gap: 2vw;
}

.down {
  margin-top: -0.9vw;
}

.up {
  margin-top: 0vw;
}
.flex h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
  font-size: 3.5vw;
}

.flex h6 {
  font-weight: 800;
  font-size: 1.5vw;
}

.flex2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  height: 5vh;
}
.flex3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2vw;
}
.p1 {
  margin-top: 1vw;
  font-weight: 600;
  font-size: 1.5vw;
}

.p2 {
  margin-top: -1.3vw;
  margin-bottom: 1.5vw;
  font-weight: 600;
  font-size: 1.5vw;
}

.numbersContainer h6 {
  color: #ffffff;
  font-weight: 500;
  margin-top: 1.5vw;
  font-size: 1.5vw;
  z-index: 51;
}

.FounderContainer {
  background-color: #000000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  width: 100vw;
  height: auto;
}

.FounderContainer h3 {
  text-align: center;
  font-size: 1.8vw !important;
  color: white;
  margin-top: 4vh;
  margin-bottom: 3vw;
  background-image: linear-gradient(
    to bottom,
    #0d6691 20%,
    #a9a932 90%,
    #a9a932 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.About {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4vw;
}

.founderImage {
  width: 30vw;
}

.founderImage img {
  width: 100%;
  height: 100%;
}

.founderAbout {
  width: 40vw;
}

.founderAbout img {
  width: 100%;
  height: 100%;
}

.featuredC {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vw;
}

.featured {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2vw;
}

.featuredC h3 {
  text-align: center;
  font-size: 1.8vw !important;
  color: white;
  margin-top: 6vh;
  margin-bottom: 3vw;
  background-image: linear-gradient(
    to bottom,
    #0d6691 20%,
    #a9a932 90%,
    #a9a932 100%
  );
  -webkit-background-clip: text;
  color: transparent;
}

.featured1 {
  width: 30vw;
}

.featured1 img {
  width: 100%;
  height: 100%;
}

.FounderContainer1 {
  display: none;
}

@media only screen and (max-width: 575px) {
  body {
    overflow-y: auto;
  }

  .AboutContainer {
    background-image: url(../../../Resources/page9bg.avif);
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #000000;
    /* overflow-y: hidden; */
    width: auto;
    height: 80vh;
    color: #ffffff;
    display: flex;
    flex-direction: column;
  }

  .AboutUs {
    width: 37vw;
    height: 24vw;
    margin: 3vw auto 3vw auto;
  }
  .ContainerFlex {
    flex-direction: column;
    margin: 5vw 10vw 0vw 10vw !important;
  }

  .Content {
    display: none;
  }

  .MobileContent {
    display: block;
    width: 90vw;
    font-size: 7vw;
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    padding: 5vw;
    border-radius: 2vw;
    margin-top: 5vw;
    background: linear-gradient(to right, #616161 0, white 10%, #757575 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 8s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
  }

  .videoContent {
    width: 80vw;
    height: 45vw;
    margin-top: 10vw;
    border-radius: 2vw;
    border: 2px solid white;
  }

  iframe {
    width: 100%;
    height: 100%;
    border-radius: 1vw;
  }

  video {
    width: 100%;
    height: 100%;
  }

  .numbersMobileContainer {
    display: block;
    width: 100vw;
    height: auto;
    color: #ffffff;
    margin-bottom: none;
  }

  .numbersBox {
    width: 100vw;
    height: auto;
    background-color: #161616ef;
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3vw;
    margin: 0 !important;
  }

  .head h3 {
    font-size: 5vw !important;
    font-weight: 800;
    margin-top: 10vw;
    margin-bottom: 10vw;
    background-image: linear-gradient(
      to bottom,
      #0d6691 20%,
      #a9a932 90%,
      #a9a932 100%
    );
    -webkit-background-clip: text;
    color: transparent;
  }

  .numbers1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    color: #ffffff;
    gap: 1vw;
  }

  .flex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 38vw;
    gap: 8vw;
  }

  .down {
    margin-top: -0.9vw;
    font-size: 7vw !important;
  }

  .up {
    margin-top: 0vw;
    font-size: 5vw !important;
  }
  .flex h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: bolder;
    font-size: 6vw;
  }

  .flex h6 {
    font-weight: 800;
    font-size: 2.5vw;
  }

  .flex2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    height: auto;
  }

  .flex2 h1{
    font-size: 8vw;

  }
  .flex3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5vw !important;
  }
  .p1 {
    margin-top: 1vw;
    font-weight: 600;
    font-size: 3vw;
  }

  .p2 {
    margin-top: -0.5vw !important;
    margin-bottom: 1.5vw !important;
    font-weight: 600;
    font-size: 3vw;
  }

  .numbersBox h6 {
    color: #ffffff;
    font-weight: 600;
    margin-top: 0vw;
    font-size: 6vw;
    z-index: 51;
  }

  .FounderContainer {
    display: none;
  }

  .FounderContainer1 {
    background-color: #000000;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
    width: 100vw;
    height: auto;
  }

  .FounderContainer1 h3 {
    text-align: center;
    font-size: 5vw !important;
    color: white;
    margin-top: 6vh;
    font-weight: 600;
    margin-bottom: 3vw;
    background-image: linear-gradient(
      to bottom,
      #0d6691 20%,
      #a9a932 90%,
      #a9a932 100%
    );
    -webkit-background-clip: text;
    color: transparent;
  }

  .About {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4vw;
  }

  .founderImage {
    width: 90vw;
    margin-top: 5vw;
  }

  .founderImage img {
    width: 100%;
    height: 100%;
  }

  .founderAbout {
    width: 70vw;
    margin-top: 5vw;
  }

  .founderAbout img {
    width: 100%;
    height: 100%;
  }

  .featured {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2vw;
    margin-top: 5vw;
  }

  .featured1 {
    width: 80vw;
    margin-top: 5vw;
  }

  .featured1 img {
    width: 100%;
    height: 100%;
  }
}
