* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.aboutSection {
  background-color: #505050;
  position: absolute;
  background-image: url("../../Resources/mainpage\ bg.avif");
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100vw;
  height: 55vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 10vw;
}

.aboutMobile {
  display: none;
}

.about {
  width: 15vw;
  display: flex;
  background-color: #2b2b2b;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* gap: 0.5vw; */
  border: 3px solid #4f4f4f;
  height: 9.5vw;
  margin-left: 19vw;
  padding: 15px;
  animation: moveRight 2s ease-in-out forwards; /* Animation properties */
  transform: translateX(0); /* Initial position */
  scroll-snap-align: start; /* Enable scroll snapping */
  scroll-snap-stop: always; /* Ensure smooth snapping behavior */
  scroll-snap-type: x mandatory; /* Enable snapping on the x-axis */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide content outside the element */
}

@keyframes moveRight {
    0% {
      transform: translateX(-100px);
    }
    100% {
      transform: translateX(0); /* Move 200px to the right */
    }
  }

.heart {
  width: 1.7vw;
  height: 1.7vw;
}

.heart img {
  width: 100%;
  height: 100%;
}

.customers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2vw;
  margin-bottom: -1vw;
}

.customers p{
  font-size: 1vw;
  color: #eaeaea;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: none !important;
}

.rating {
  font-size: 2.6vw;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #eaeaea;
  margin-top: 1vw;
}

.ratingStars {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.1vw;
}

.ratingStars p {
  font-size: 0.9vw;
  color: #ffffff54;
  text-align: left;
  font-weight: 600;
  letter-spacing: 1px;
}

.stars {
  width: 7vw;
  margin-top: -5px;
}

.stars img {
  width: 100%;
  height: 100%;
}

.helloVeeru {
  width: 15vw;
  height: 5vw;
  display: flex;
  flex-direction: row;
  margin-top: 15vw;
  margin-left: -5vw;
  margin-right: 2.5vw;
  gap: 1vw;
  border: 2px solid #4f4f4f;
  padding: 0.5vw 0.5vw 0 0.5vw;
  background-color: #2b2b2b;
  z-index: 5;

  animation: moveRight 2s ease-in-out forwards; /* Animation properties */
  transform: translateX(0); /* Initial position */
  scroll-snap-align: start; /* Enable scroll snapping */
  scroll-snap-stop: always; /* Ensure smooth snapping behavior */
  scroll-snap-type: x mandatory; /* Enable snapping on the x-axis */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide content outside the element */
}

.veerulogo {
  width: 3vw;
  height: 3vw;
}

.veerulogo img {
  width: 100%;
  height: 100%;
}
.names {
  display: flex;
  flex-direction: column;
  gap: -1vw !important;
}

.names h3 {
  color: #eaeaea;
  font-size: 1.5vw;
}

.names p {
  color: #ffffff54;
  font-size: 1vw;
  font-weight: 500;
}

.veeru {
  width: 22vw;
  height: 22vw;
  margin-left: -5vw;
  margin-top: 0vw;
}

.veeru img {
  width: 100%;
  height: 100%;
}

.follow {
  width: 13vw;
  height: 17vw;
  border: 4px solid #4f4f4f;
  display: flex;
  margin-top: 4vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 5vw;
  background-color: #2b2b2b;

  animation: moveLeft 2s ease-in-out forwards; /* Animation properties */
  transform: translateX(200px); /* Initial position at 200px */
  scroll-snap-align: start; /* Enable scroll snapping */
  scroll-snap-stop: always; /* Ensure smooth snapping behavior */
  scroll-snap-type: x mandatory; /* Enable snapping on the x-axis */
  white-space: nowrap; /* Prevent line breaks */
  overflow: hidden; /* Hide content outside the element */
}

@keyframes moveLeft {
    0% {
      transform: translateX(200px); /* Start from 200px */
    }
    100% {
      transform: translateX(0); /* Move to the left (0px) */
    }
  }

.bigheart {
  width: 8.5vw;
  height: 8.5vw;
}

.likeContainer {
  width: 11vw;
  display: flex;
  flex-direction: row;
}

.likeContainer h3 {
  color: #eaeaea;
  font-size: 1.6vw;
}

.like {
  width: 1.8vw;
  height: 1.8vw;
}

.subscribe {
  color: #ffffff54;
  font-weight: 700;
  font-size: 1vw;
}

.bigheart img,
.like img {
  width: 100%;
  height: 100%;
}

.socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5vw;
  margin-top: 0.3vw;
}

.instagram,
.twitter {
  font-size: 1vw;
  border: 2px solid #4f4f4f;
  margin: 0 !important;
  padding: 2px 7px;
  background-color: #464646;
  color: #eaeaea;
}

@media only screen and (max-width: 575px) {
  .aboutSection {
    display: none;
  }

  .aboutMobile {
    display: block;
    width: 100vw;
    height: auto;
    background-image: url(../../Resources/landing\ page\ new-1\ bg.avif);
    background-position: center;
    background-size: 100% auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: -10vw;
    padding-bottom: 5vw;
  }

  .flex-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .helloVeeru {
    width: 40vw;
    height: 14vw;
    display: flex;
    flex-direction: row;
    margin-top: 0vw;
    margin-left: 3vw;
    margin-right: 0vw;
    gap: 2vw;
    border: 2px solid #4f4f4f;
    padding: 1.5vw 1.5vw 2.5vw 1.5vw;
    background-color: #2b2b2b;
    z-index: 5;
  }

  .veerulogo {
    width: 10vw;
    height: 10vw;
  }

  .veerulogo img {
    width: 100%;
    height: 100%;
  }

  .names {
    display: flex;
    flex-direction: column;
    gap: -1vw !important;
  }

  .names h3 {
    color: #eaeaea;
    font-size: 3.5vw;
  }

  .names p {
    color: #ffffff54;
    font-size: 3vw;
    font-weight: 500;
  }

  .veeru {
    width: 45vw;
    height: 45vw;
    margin-left: 3vw;
  }

  .veeru img {
    width: 100%;
    height: 100%;
  }

  .about {
    width: 40vw;
    display: flex;
    background-color: #2b2b2b;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 3px solid #4f4f4f;
    height: 25vw;
    margin-left: 3vw;
    margin-top: 4vw;
    padding: 2vw;
  }

  .heart {
    width: 6vw;
    height: 6vw;
  }

  .heart img {
    width: 100%;
    height: 100%;
  }

  .customers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    margin-bottom: -1vw;
  }

  .customers p {
    font-size: 2.8vw;
    color: #eaeaea;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    margin-bottom: none !important;
    margin-top: 1vw !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rating {
    font-size: 7vw;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #eaeaea;
    margin-bottom: 1vw;
  }

  .ratingStars {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    margin-top: -2vw;
  }

  .ratingStars p {
    font-size: 3vw;
    color: #ffffff54;
    text-align: left;
    font-weight: 600;
    margin-top: 0;
  }

  .stars {
    width: 20vw;
  }

  .stars img {
    width: 100%;
    height: 100%;
  }
}
