* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    margin: 0 !important;
    padding: 0 !important;
    /* overflow-x: hidden;
    overflow-y: auto; */
    scroll-behavior: auto;
    background-color: black;
    width: 100vw;
  }

  .newspapers{
    background-color: black;
    padding: 5vh 0;

  }

  .row{
   display: flex;
   width: 100vw;
  }

  .row img{
    width: 100%;
    height: 100%;
  }

  .rowMobile{
    display: none;
  }

  @media only screen and (max-width: 575px){
    .row{
        display: none;
    }

    .rowMobile{
        display: block;
    }

    .rowFlex{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5vw;
        margin-bottom: 10vw;
    }

    .row1, .row2, .row3{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .paper{
       width: 25vw;
    }

    .paper img{
        width: 100%;
        height: 100%;
    }


  }
