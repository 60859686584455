.scrolling-banner {
  overflow: hidden; 
  position: fixed;
  width: 100vw; /* Set to desired width */
  height: 5vh; /* Set to desired height */
  background-color: #f0f0f0; /* Optional background color */
  display: flex; /* Aligns the child divs in a row */
  align-items: center;
  padding-top: 3vh;
  background-color: rgb(246, 245, 245);
  padding-bottom: 3vh;
  z-index: 100;
}

.scrolling-banner h1{
  color: #000;
  font-size: 1vw;
  text-align: center;
  width: 100vw;
}

.scrolling-text {
  display: inline-block;
  padding-left: 100%; /* Push the text offscreen to the right initially */
  animation: scroll-left 20s linear infinite; /* Adjust timing as needed */
  font-weight: 600;
}

/* @keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%); 
  }
} */

@media only screen and (max-width: 575px){
  .scrolling-banner{
    padding-top: 4vh;

  }

  .scrolling-banner h1{
    color: #000;
    font-size: 4vw;
    text-align: center;
    width: 100vw;
  }

}
