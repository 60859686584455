* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  scroll-behavior: auto;
  background-color: #f6f6f6 !important;
}

.Test3Container {
  background-color: #0d0d0d;
  background-image: url(../../../Resources/testimonial-bg3.avif);
  background-position: center;
  background-size: 100vw 100%;
  background-repeat: no-repeat;
  width: 100vw;
  height: 150vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}

.Test3Container h1 {
    font-size: 2.5vw;
    color: #cecccc;
    margin-top: 5vw;
    letter-spacing: 1px;
}

.screenshot{
 /* background-color: black; */
 width: 70vw;

}

.screenshot img{
  width: 100%;
  height: 100%;
}

.mobileTest3{
  display: none;
}


@media only screen and (max-width: 575px){
  .Test3Container{
    display: none;
  }

  .mobileTest3{
    display: block;
    width: 100vw;
    height: 100vh;
    background-image: url(../../../Resources/testimonialmobilebg3.avif);
    background-position: cover;
    background-size: 100vw 100vh;
    background-repeat: no-repeat;
  }

  .mobileTest3 h1{
    color: #f6f6f6;
    padding-top: 5vw;
    text-align: center;
  }
}
