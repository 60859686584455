@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  /* overflow-x: hidden;
  overflow-y: auto; */
  scroll-behavior: auto;
}

.hero {
  background-image: url(../../Resources/10x\ blck.avif);
  background-position: bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heroMobile {
  display: none;
}

.hero h5 {
  font-family: "Poppins", sans-serif;
  font-size: 1.5vw;
  font-weight: 600;
  color: #dbdbdb;
  letter-spacing: 5px;
  margin-top: 0vw;
}

.hero h6 {
  font-family: "Poppins", sans-serif;
  width: 55vw;
  line-height: 1.5;
  font-size: 1.5vw;
  font-weight: 300;
  margin-top: 1.5vw;
  color: #f1f1f1;
  text-align: center;
}

.animationHeader {
  width: auto;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(to right, #616161 0, white 10%, #757575 20%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 4s infinite linear;
  animation-fill-mode: forwards;
  -webkit-text-size-adjust: none;
  font-weight: 650;
  font-size: 3.5vw;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 1vw;
}

.animationHeader {
  top: 28% !important;
  margin-top: 2vw;
}

@keyframes shine {
  0% {
    background-position: 0px;
  }

  60% {
    background-position: 480px;
  }

  100% {
    background-position: 900px;
  }
}

.button {
  width: 20vw;
  font-size: 1.2vw;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 1vw;
  margin-top: 3vw;
  margin-bottom: 3vw;
  background-color: #175779;
  color: #fcfcfc;
  border-radius: 0.6vw;
  background-image: linear-gradient(200deg, #175779 50%, #d1b442 100%);
}

@media only screen and (max-width: 575px) {
  .hero {
    display: none;
  }

  .heroMobile {
    display: block;
    width: 100vw;
    height: 100vh;
    background-image: url(../../Resources/landing\ page\ new-1\ bg.jpg);
    background-position: center;
    background-size: 100% auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  span {
    font-size: 2.5vw;
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    color: #eee4e4;
    margin-top: 2vw;
    width: 80vw;
  }

  h6 {
    font-family: "Poppins", sans-serif;
    width: 80vw;
    line-height: 1.2;
    font-size: 1.2vw;
    font-weight: 300;
    margin-top: 1.2vw;
    color: #aca7a7;
    text-align: center;
  }

  .growth {
    width: 60vw;
    margin-top: -20vw;
  }

  .growth img {
    width: 100%;
    height: 100%;
  }

  .heading {
    font-size: 8vw;
    width: 80vw;
    margin: 8vw 5vw 5vw 5vw;
    line-height: 12vw;
    background: linear-gradient(to right, #616161 0, white 10%, #757575 20%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 10s infinite linear;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
    white-space: wrap;
  }

  .heroMobile h6 {
    font-size: 6vw;
    width: 80vw;
    text-align: left;
    font-weight: 500;
    margin-top: 5vw;
    line-height: 1.5;
    margin-bottom: 10vw;
  }

  .button {
    width: 68vw;
    height: 13vw;
    font-size: 4vw;
    text-align: center;
    font-weight: 600;
    text-align: center;
    margin-top: 5vw;
    padding-top: 3vw;
    background-color: #175779;
    cursor: pointer;
    z-index: 10;
    color: #fcfcfc;
    border-radius: 2vw;
    background-image: linear-gradient(200deg, #175779 50%, #d1b442 100%);
  }
}
