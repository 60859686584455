* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  scroll-behavior: auto;
}

.ServiceContainer {
  width: 100vw;
  /* height: 100vh; */
}

.navigation {
  background-image: url(../../Resources/servicepagebg-1.avif);
  background-position: cover;
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6 !important;
}

.MarketContainer{
  width: 100vw;
  height: 10vh;
}

.Marketnavigation{
  background-image: url(../../Resources/servicepagebg-1.avif);
  background-position: cover;
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f6f6 !important;
}



.gradientBorder {
  width: 18vw;
  text-align: center;
  font-size: 2.5vw;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  padding: 0.5vw 0.5vw 0.1vw 0.5vw;
  margin-bottom: 2vw;
  margin: 10vw 40vw 5vw 40vw;
  color: #ffffff;
  border-bottom: 1px solid white;
 
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1vw;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 4vw;
  z-index: 5;
  /* position: absolute; */
  /* top: 40%; */
}

.card {
  width: 15vw;
  border-width: 3px;
  border-style: solid;
  border-image: linear-gradient(to top, #0d6691 20%, #a9a932 90%, #a9a932 100%);
  border-image-slice: 1;
  border-radius: 15px;
  margin-top: 5vw;
}

.card img {
  width: 100%;
  height: 100%;
}

.links p {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.8vw !important;
  font-weight: 800;
  font-size: 1.2vw;
}

.links a.active {
  background-color: pink !important;
}

.serviceMobile {
  display: none;
}

.button {
  width: 20vw;
  font-size: 1.5vw;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  padding: 1vw;
  margin-top: 3vw;
  margin-bottom: 3vw;
  background-color: #175779;
  color: #fcfcfc;
  border-radius: 0.6vw;
  background-image: linear-gradient(200deg, #175779 50%, #d1b442 100%);
  margin: 3vw 40vw 5vw 40vw;
}

@media only screen and (max-width: 575px) {
  .ServiceContainer {
    display: none;
  }

  .MarketContainer{
    display: none;
  }

  .serviceMobile {
    display: block;
  }

  .Mobilenavigation {
    width: 100vw;
    height: auto;
    color: #ffffff;
    background-image: url(../../Resources/service\ page\ mobile\ bg.jpg);
    background-position: cover;
    background-size: 100% 100vh;
    background-repeat: no-repeat;
    background-color: #000000;
    /* overflow-y: hidden; */
    height: 100vh;
  }

  .gradientBorder {
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(
      to top,
      #0d6691 20%,
      #a9a932 90%,
      #a9a932 100%
    );
    border-image-slice: 1;
    border-radius: 15px;
    width: 48vw;
    text-align: center;
    font-size: 5.5vw;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    padding: 1.5vw 0.5vw 1.5vw 0.5vw;
    color: #ffffff;
    margin-top: 30vw;
    margin-bottom: 40vw;
  }

  .accordion-header {
    margin-bottom: 5vw;
    color: #0d6691 !important;
  }

  button.accordion-button.collapsed {
    color: #000000 !important;
    margin: 10vw 20vw 10vw 20vw !important;
    text-align: center;
  }

  .accordion-button {
    background-color: #0d6691 !important;
    padding: 10rem !important;
  }

  .accordion-item h2 {
    text-align: center;
    font-size: 5rem !important;
  }

  .accordion-item .collapse h2 {
    padding-bottom: 10vh;
  }

  .accordion-button h2 {
    font-size: 2rem !important;
  }

  .call{
    margin-top: 10vw;
  }

  .button {
    width: 60vw;
    margin: 0vw 20vw 3vw 20vw;
    height: 13vw;
    font-size: 5vw;
    text-align: center;
    font-weight: 600;
    text-align: center;
    margin-top: 3vw;
    padding-top: 3vw;
    background-color: #175779;
    cursor: pointer;
    z-index: 10;
    color: #fcfcfc;
    border-radius: 2vw;
    background-image: linear-gradient(200deg, #175779 50%, #d1b442 100%);
  
  }
}
